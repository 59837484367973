<template>
  <div class="custom-margin">
    <h1 class="d-block text-center">Essa página não foi encontrada</h1>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
};
</script>

<style scoped>
.custom-margin {
  margin-top: 80px;
}
</style>
